import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import {usePage} from "@inertiajs/react";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isAnchorActive (anchor: string) {
  const { url } = usePage();

  return url === `${route('home')}/#${anchor}`;
}

export const MAX_CHARS_ACHIEVEMENT = 180